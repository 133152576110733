import "./joinbts.scss"
import * as React from "react"
import { StaticImage } from 'gatsby-plugin-image'

const Joinbts = () => (
  <section className="section-joinbts">
        <div className="div-call">
          <div className="container-slogan">
            <StaticImage
              className="icon-joinbts"
              width={74}
              alt="bg Join BTS Family"
              src="../../images/icons/i-am-bts.svg"
              formats={["auto", "webp", "avif"]}
            />
            <h3 className="text-white fw-semi-bold">Join the BTS Family</h3>
          </div>
          <div className="container-phrase d-none d-md-block">
            <StaticImage
              className="icon-quotes"
              width={85}
              alt="Quotes"
              src="../../images/refer/quotes.png"
              formats={["auto", "webp", "avif"]}
            />
            <br></br>
            <h3 className="text-white fw-semi-bold">
              Passion is not what <br></br> you're good at.
              <br></br><br></br>
              It's what you <br></br>
              enjoy the most.
            </h3>
          </div>
        </div>
  </section>
)

export default Joinbts
